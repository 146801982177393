import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";
import Heading from "../Heading";
import "./styles.css";
import { authHeader } from "../../actions/auth-header";
import axios from "axios";
import config from "../../config/config";
import Loader from "../Loader";

const Option = Select.Option;
const baseUrl = config.apiUrl + "HolidayHours/";
const requestOptions = {
  headers: authHeader(),
};

function HolidayHour(props) {
  const [holidayValue, setHoliday] = useState("0");
  const [MondayValue, setMonday] = useState("0");
  const [TusesdayValue, setTusesday] = useState("0");
  const [WednessdayValue, setWednessday] = useState("0");
  const [ThursdayValue, setThursday] = useState("0");
  const [FridayValue, setFridayday] = useState("0");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchHolidayHourData();
  }, []);

  const fetchHolidayHourData = async () => {
    setIsLoading(true);
    axios
      .get(baseUrl + "GetHolidayHours", requestOptions)
      .then((response) => {
        setIsLoading(false);
        setHoliday(boolToString(response.data.holiday));
        setMonday(boolToString(response.data.monday));
        setTusesday(boolToString(response.data.tuesday));
        setWednessday(boolToString(response.data.wednesday));
        setThursday(boolToString(response.data.thursday));
        setFridayday(boolToString(response.data.friday));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function updateHolidayHour(trigerdayNumber, DayValue) {
    axios.post(
      baseUrl + "UpdateHolidayHours/",
      {
        trigerEventDay: trigerdayNumber,
        value: ConvertStringToBool(DayValue),
      },
      requestOptions
    );
  }
  function boolToString(val) {
    return val === true ? "1" : "0";
  }

  function ConvertStringToBool(val) {
    return val === "1" ? true : false;
  }
  function handleHoliday(e) {
    setHoliday(e, 0);

    if (e == 0) {
      updateHolidayHour(0, e);
      setMonday("0");
      setTusesday("0");
      setWednessday("0");
      setThursday("0");
      setFridayday("0");
    }
  }
  function handleMonday(e) {
    setMonday(e);
    updateHolidayHour(2, e);
  }
  function handleTuesday(e) {
    setTusesday(e);
    updateHolidayHour(3, e);
  }
  function handleWednesday(e) {
    setWednessday(e);
    updateHolidayHour(4, e);
  }
  function handleThursday(e) {
    setThursday(e);
    updateHolidayHour(5, e);
  }
  function handleFriday(e) {
    setFridayday(e);
    updateHolidayHour(6, e);
  }

  return (
    <div>
      <div className="regions">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <Heading heading="HOLIDAY HOURS" />
            <Form layout="vertical" className="weekend__form">
              <Form.Item label="All Staff Holiday This Week" className="shift__field">
                <div style={{ width: 100 }}>
                  <Select name="holiday" value={holidayValue} onChange={handleHoliday}>
                    <Option value="1">Yes</Option>
                    <Option value="0">No</Option>
                  </Select>
                </div>
              </Form.Item>
              {holidayValue == 1 && (
                <div className="holiday__weeks">
                  <Form.Item label="Monday" className="shift__field">
                    <Select name="monday" value={MondayValue} onChange={handleMonday}>
                      <Option value="1">Yes</Option>
                      <Option value="0">No</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Tuesday" className="shift__field">
                    <Select name="tuesday" value={TusesdayValue} onChange={handleTuesday}>
                      <Option value="1">Yes</Option>
                      <Option value="0">No</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Wednesday" className="shift__field">
                    <Select name="wednesday" value={WednessdayValue} onChange={handleWednesday}>
                      <Option value="1">Yes</Option>
                      <Option value="0">No</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Thursday" className="shift__field">
                    <Select name="thursday" value={ThursdayValue} onChange={handleThursday}>
                      <Option value="1">Yes</Option>
                      <Option value="0">No</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Friday" className="shift__field">
                    <Select name="friday" value={FridayValue} onChange={handleFriday}>
                      <Option value="1">Yes</Option>
                      <Option value="0">No</Option>
                    </Select>
                  </Form.Item>
                </div>
              )}
            </Form>
            <p className="weekend__schedule">
              During Holiday Hours, calls will follow a nights and weekends schedule and be directly
              sent to voicemail. The regular nights and weekend schedule is 8:30 AM - 6:30 PM.
            </p>
          </div>
        )}{" "}
      </div>
    </div>
  );
}
export default HolidayHour;
