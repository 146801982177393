import React, { Component } from "react";
import Tabs from "../Tabs";
import RegionWise from "../RegionWise";
import HolidayHour from "../HolidayHour";
import ConfirmationModal from "../ConfirmationModal";
import "./styles.css";

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
        tab: 1,
        confirmationPopupVisible: false,
        tempTab:1
    };
    this.changeTab = this.changeTab.bind(this);
    }

    componentDidMount() {
        localStorage.setItem("isUnsaveToggle", false);
    }

    hidePopup = () => {
        this.setState({ confirmationPopupVisible: false });
    }
    performAction = () => {
        localStorage.setItem("isUnsaveToggle", false);
        this.setState({ tab: this.state.tempTab, confirmationPopupVisible: false });
    }
    changeTab(tab) {
        var temp = localStorage.getItem("isUnsaveToggle");
        if (temp=="true") {
            this.setState({ confirmationPopupVisible: true, tempTab:tab});
        }
        else
           this.setState({ tab });
  }
  render() {
    const { tab } = this.state;
    return (
      <div className="schedule__wrapper">
        <div className="schedule__tabsContainer">
          <Tabs region="Region 1" onClick={() => this.changeTab(1)} selected={tab === 1} />
          <Tabs region="Region 2" onClick={() => this.changeTab(2)} selected={tab === 2} />
          <Tabs region="Region 3" onClick={() => this.changeTab(3)} selected={tab === 3} />
          <Tabs region="Region 4" onClick={() => this.changeTab(4)} selected={tab === 4} />
          <Tabs region="Region 5" onClick={() => this.changeTab(5)} selected={tab === 5} />
          <Tabs region="Region 6" onClick={() => this.changeTab(6)} selected={tab === 6} />
          <Tabs region="Region 7" onClick={() => this.changeTab(7)} selected={tab === 7} />
          <Tabs region="Region 8" onClick={() => this.changeTab(8)} selected={tab === 8} />
          <Tabs region="Region 9" onClick={() => this.changeTab(9)} selected={tab === 9} />
          <Tabs region="Region 10" onClick={() => this.changeTab(10)} selected={tab === 10} />
          <Tabs region="Youth Program" onClick={() => this.changeTab(11)} selected={tab === 11} />
          <div className="holiday__tab">
            <Tabs region="Holiday Hours" onClick={() => this.changeTab(12)} selected={tab === 12} />
          </div>
        </div>
        <div>
          {tab === 1 && <RegionWise region={1} />}
          {tab === 2 && <RegionWise region={2} />}
          {tab === 3 && <RegionWise region={3} />}
          {tab === 4 && <RegionWise region={4} />}
          {tab === 5 && <RegionWise region={5} />}
          {tab === 6 && <RegionWise region={6} />}
          {tab === 7 && <RegionWise region={7} />}
          {tab === 8 && <RegionWise region={8} />}
          {tab === 9 && <RegionWise region={9} />}
          {tab === 10 && <RegionWise region={10} />}
          {tab === 11 && <RegionWise region={100} />}
          {tab === 12 && <HolidayHour region={12} />}
            </div>

            <ConfirmationModal
                title={"Confirm"}               
                visible={this.state.confirmationPopupVisible}
                buttonName={"OK"}
                message={"Your have unsaved changes, are you sure you want to leave"}
                hidePopup={this.hidePopup}
                PerformAction={this.performAction}
            />
      </div>
    );
  }
}
export default Schedule;
