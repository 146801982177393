import React from "react";
import "../../config/global_styles.css";
import "./styles.css";

function Heading({ heading, addButton, onClick, downloadBtn, contents }) {
  return (
    <div className="heading">
      <h5>{heading}</h5>
      {addButton && <i className="fa fa-plus" aria-hidden="true" onClick={onClick}></i>}
      {downloadBtn && (
        <div>
          {contents.map((content, index) => (
            <span key={index}>{content}</span>
          ))}
        </div>
      )}
    </div>
  );
}
export default Heading;
