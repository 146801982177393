import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Table, Space, Form } from "antd";
import "antd/dist/antd.css";
import config from "../../config/config";
import * as actions from "../../actions/dEmployees";
import Heading from "../Heading";
import menu from "../../assets/images/menu.png";
import sortIcon from "../../assets/images/sort.svg";
import AddModal from "../AddModal";
import EditModal from "../EditModal";
import EditableCell from "./EditableCell";
import "../../../node_modules/antd/dist/antd.css";
import { useToasts } from "react-toast-notifications";
import { authHeader } from "../../actions/auth-header";
import ConfirmationModal from "../ConfirmationModal";

import axios from "axios";
import "./styles.css";
const originData = [];
const baseUrl = config.apiUrl + "User/";
const requestOptions = {
  headers: authHeader(),
};

const EmployeeTable = ({ classes, ...props }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [formVisible, setFormVisible] = useState("");
  const [lastInsertedId, setlastInsertedId] = useState(0);
  const [confirmationPopupVisible, setConfirmationPopupVisible] = useState("");
  const [editFormVisible, setEditFormVisible] = useState("");
  const [userData, setUserData] = useState([]);
  const [region, setRegion] = useState([]);
  const [isShowUndoPopup, setIsShowUndoPopup] = useState(true);
  const [userList, setuserlist] = useState(props.dCandidateList);
  const [sortBy, setSortBy] = useState("");
  const [nameSortOrder, setNameSortOrder] = useState("descend");
  const [regionSortOrder, setRegionSortOrder] = useState("descend");
  //toast msg.
  const { addToast } = useToasts();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user === "" || user == null) {
      window.location.href = "/login";
    }
    props.fetchAllDCandidates();
    setuserlist(props.dCandidateList);
  }, []); //componentDidMount

  useEffect(() => {
    setuserlist(props.dCandidateList);
  }, [props.dCandidateList]);

  const isEditing = (record) => record.key === editingKey;
  const openModal = () => {
    setFormVisible(true);
  };
  const hideForm = () => {
    setFormVisible(false);
    props.fetchAllDCandidates();
    setuserlist(props.dCandidateList);
  };

  const LastId = (data) => {
    setlastInsertedId(data);
    setTimeout(() => {
      setIsShowUndoPopup(false);
    }, 4500);
  };

  const hideEditForm = () => {
    setEditFormVisible(false);
    props.fetchAllDCandidates();
    setuserlist(props.dCandidateList);
  };
  const hidePopup = () => {
    setConfirmationPopupVisible(false);
    props.fetchAllDCandidates();
    setuserlist(props.dCandidateList);
  };

  const setUseKey = () => {
    setConfirmationPopupVisible(true);
  };

  const sort = (sortby) => {
    var tempUserList = props.dCandidateList;
    if (sortby == "name") {
      if (nameSortOrder == "desc") {
          tempUserList.sort(function (x, y) {
              var fullName1 = x.name.toUpperCase().split(' '),                 
                  lastName1 = fullName1.length > 1 ? fullName1[fullName1.length - 1] : " ";
              lastName1 = lastName1 + x.name.toUpperCase();
              var fullName2 = y.name.toUpperCase().split(' '),
                  lastName2 = fullName2.length > 1 ? fullName2[fullName2.length - 1] : " ";
              lastName2 = lastName2 + x.name.toUpperCase();
              let a = lastName1,    
                  b = lastName2;
          return a == b ? 0 : a > b ? 1 : -1;
        });
        setNameSortOrder("asc");
      } else {
        tempUserList.sort(function (x, y) {
            var fullName1 = x.name.toUpperCase().split(' '),
                lastName1 = fullName1.length > 1 ? fullName1[fullName1.length - 1] : " ";
            lastName1 = lastName1 + x.name.toUpperCase();
            var fullName2 = y.name.toUpperCase().split(' '),
                lastName2 = fullName2.length > 1 ? fullName2[fullName2.length - 1] : " ";
            lastName2 = lastName2 + x.name.toUpperCase();
            let a = lastName1,
                b = lastName2;
          return a == b ? 0 : a > b ? -1 : 1;
        });
        setNameSortOrder("desc");
      }
    }

    if (sortby == "region") {
      if (regionSortOrder == "desc") {
        tempUserList.sort(function (x, y) {
          let a = Number(x.region.slice(7, 9)),
            b = Number(y.region.slice(7, 9));
          return a == b ? 0 : a > b ? 1 : -1;
        });
        setRegionSortOrder("asc");
      } else {
        tempUserList.sort(function (x, y) {
          let a = Number(x.region.slice(7, 9)),
            b = Number(y.region.slice(7, 9));
          return a == b ? 0 : a > b ? -1 : 1;
        });
        setRegionSortOrder("desc");
      }
    }
    setuserlist([...tempUserList]);
  };

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      phoneNumber: "",
      region: "",
      role: "",
      email: "",
      ...record,
    });
    setEditingKey(record.key);
    setUserData(record);
    axios
      .get(config.apiUrl + "Region/" + "GetRegions", requestOptions)
      .then(function (response) {
        setRegion(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    setEditFormVisible(true);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const deleteItem = async (key) => {
    axios
      .delete(baseUrl + "DeleteEmployee/" + key, requestOptions)
      .then(function (response) {
        setEditingKey("");
        setTimeout(() => {
          props.fetchAllDCandidates();
          addToast("Employee Deleted successfully", { appearance: "success" });
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const hardDeleteItem = () => {
    axios
      .delete(baseUrl + "HardDeleteUser/" + lastInsertedId, requestOptions)
      .then(function (response) {
        setlastInsertedId(0);
        setTimeout(() => {
          setConfirmationPopupVisible(false);
          //   props.fetchAllDCandidates();
          addToast("User Deleted successfully", { appearance: "success" });
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const validate = (item) => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (item.name === null || item.name === "") {
      addToast("Employee Name Required!", { appearance: "error" });
      return false;
    }
    if (item.phoneNumber === null || item.phoneNumber === "") {
      addToast("Employee Number Required!", { appearance: "error" });
      return false;
    }
    if (item.region === null || item.region === "") {
      addToast("Employee Region Required!", { appearance: "error" });
      return false;
    }
    if (item.role === null || item.role === "") {
      addToast("Employee Role Required!", { appearance: "error" });
      return false;
    }
    if (item.email === null || item.email === "") {
      addToast("Employee Email Required!", { appearance: "error" });
      return false;
    } else if (!pattern.test(item.email)) {
      addToast("Employee Email Required!", { appearance: "error" });
      return false;
    }
    if (item.phoneNumber != null) {
      const numOfDig = item.phoneNumber.toString();
      if (numOfDig.length >= 11 || numOfDig.length <= 9) {
        addToast("Employee Number must be 10 digit!", { appearance: "error" });
        return false;
      }
    }
    return true;
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const nsf = await form.getFieldValue("name");
      const ugu = props.dCandidateList;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      const indext = ugu.findIndex((item) => key === item.key);
      const itemNew = ugu[indext];
      if (validate(itemNew)) {
        const newUrl = "UpdateEmployee/";
        axios.post(
          baseUrl + newUrl,
          {
            Key: itemNew.key,
            Name: itemNew.name,
            PhoneNumber: itemNew.phoneNumber.toString(),
            Region: itemNew.region,
            Role: itemNew.role,
            Email: itemNew.email,
          },
          requestOptions
        );
        addToast("Employee Update successfully", { appearance: "success" });
        if (index > -1) {
          const item = newData[index];
          const phone_number = row.number;
          row.number = phone_number;
          newData.splice(index, 1, { ...item, ...row });
          setData(newData);
          setEditingKey("");
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey("");
        }
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleName = (e) => {
    var data = userData;

    data.name = e.target.value;
    setUserData(data);
  };

  const columns = [
    {
      title: (
        <span className="employee_column">
          User Name
          <img className="menu__icon" src={sortIcon} alt="menu" onClick={() => sort("name")} />
        </span>
      ),
      width: "20%",
      dataIndex: "name",
      key: "name",
      // defaultSortOrder: "ascend",
      // sorter: (a, b) => a.name.localeCompare(b.name),
      // sortDirections: ["ascend", "descend", "ascend"],
      editable: false,
      render: (text) => <span className="table__element table__element--bold">{text}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "13%",
      editable: false,
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
      editable: false,
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: (
        <span className="employee_column" style={{ gridTemplateColumns: "120px 1fr" }}>
          Primary Region
          <img className="menu__icon" src={sortIcon} alt="menu" onClick={() => sort("region")} />
        </span>
      ),
      dataIndex: "region",
      // defaultSortOrder: "ascend",
      // sorter: {
      //   compare: (a, b) => Number(a.region.slice(7, 9)) - Number(b.region.slice(7, 9)),
      // },
      // sortDirections: ["ascend", "descend", "ascend"],
      key: "region",
      width: "20%",
      editable: false,
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "15%",
      editable: false,
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Speaks Spanish",
      dataIndex: "isSpeekSpanish",
      key: "isSpeekSpanish",
      width: "15%",
      editable: false,
      render: (record, text) => (
        <span className="table__element">{!text.isSpeekSpanish ? "No" : "Yes"}</span>
      ),
    },
    {
      key: "action",
      width: "30%",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <Space size="middle">
            <div className="editable-row-operations">
              <span onClick={() => edit(record)}>
                <i className="fas fa-pen penIcon"></i>
              </span>
            </div>
          </Space>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        const checkInput = (index) => {
          switch (index) {
            case "phoneNumber":
              return "number";
            case "region":
              return "select";
            case "role":
              return "select1";
            case "email":
              return "email";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });
  return (
    <div className="employee">
      <Heading addButton heading="ADD OR EDIT USERS" onClick={openModal} />
      {lastInsertedId > 0 && isShowUndoPopup ? (
        <span className="addUser__popup">
          New User Added
          <span onClick={() => setUseKey(lastInsertedId)}>UNDO</span>
        </span>
      ) : null}

      <div className="employeeTable__wrapper">
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={userList}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel,
            }}
            scroll={{ x: 1250 }}
          />
        </Form>
      </div>
      <AddModal
        visible={formVisible}
        openModal={openModal}
        hideForm={hideForm}
        LastInsertId={LastId}
        userList={userList}
      />

      <EditModal
        visible={editFormVisible}
        userModal={userData}
        hideForm={hideEditForm}
        totalRegion={region}
        handleUserName={handleName}
        userList={userList}
      />
      <ConfirmationModal
        title="Please Confirm!"
        visible={confirmationPopupVisible}
        buttonName={"CONFIRM"}
        message={"Are you sure you want to undo this action?"}
        hidePopup={hidePopup}
        PerformAction={hardDeleteItem}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  dCandidateList: state.dEmployees.list,
  updateEmployeeData: state.dEmployees.list,
});

const mapActionToProps = {
  fetchAllDCandidates: actions.fetchAll,
  updateEmployee: actions.update,
};

export default connect(mapStateToProps, mapActionToProps)(EmployeeTable);
