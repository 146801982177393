import axios from "axios";
import config from '../config/config'
import { authHeader } from '../actions/auth-header'

const baseUrl = config.apiUrl;

const requestOptions = {
    headers: authHeader()
};

export default {

    dEmployees(url = baseUrl) {
        return {
            fetchAll: () => axios.get(url+'User/'+'GetEmployees', requestOptions),
            fetchAllRegion: () => axios.get(url+'Region/GetRegions', requestOptions),
            fetchRegionPerson: (id) => axios.get(url+'Region/GetEmployeesByRegionId/'+id, requestOptions),
            fetchById: id => axios.get(url + id, requestOptions),
            create: newRecord => axios.post(url, newRecord, requestOptions),
            update: (updateRecord) => axios.put(url+"User/UpdateEmployee/" , updateRecord, requestOptions),
            delete: id => axios.delete(url + id, requestOptions)
        }
    }
}