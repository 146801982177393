import React from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "../components/Loader";
import Loadable from "react-loadable";

const Login = Loadable({
  loader: () => import("../layout/Login"),
  loading: Loader,
});
const CreatePwd = Loadable({
  loader: () => import("../layout/CreatePwd"),
  loading: Loader,
})

function AuthRouter() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <Route path="/change-password"  exact component={CreatePwd}/>
    </Switch>
  );
}

export default AuthRouter;
