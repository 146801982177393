import React, { useState, useEffect } from "react";
import { Table, DatePicker, Popover, Select, Tag } from "antd";
import Heading from "../Heading";
import menu from "../../assets/images/menu.png";
import "./styles.css";
import Loader from "../Loader";
import { authHeader } from "../../actions/auth-header";
import axios from "axios";
import config from "../../config/config";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { CSVLink } from "react-csv";

import ConfirmationModal from "../ConfirmationModal";

const Option = Select.Option;
const baseUrl = config.apiUrl + "CallLogs/";
const requestOptions = {
  headers: authHeader(),
};
const dateFormat = "MMMM D, YYYY";

function Dashboard(props) {
  const [dataSource, setDataSource] = useState([]);
  const [totalCalls, setTotalCalls] = useState(0);
  const [uniqueCalls, setUniqueCalls] = useState(0);
  const [callsToVoiceMail, setCallsToVoiceMail] = useState(0);
  const [nameSortOrder, setNameSortOrder] = useState("descend");

  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [Region, setRegion] = useState("0");
  const [User, setUser] = useState("0");
  const [AnsweredByUserList, setAnsweredByUserList] = useState([]);
  //const { addToast } = useToasts();

  const [CurentMonthDataSource, setCurentMonthDataSource] = useState([]);
  const [confirmationMessage, setconfirmationMessage] = useState("");
  const [confirmationPopupVisible, setconfirmationPopupVisible] = useState(false);
  const [isLoding, setIsLoading] = useState(false);

  useEffect(() => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    setStartDate(moment(firstDay));
    setEndDate(moment(lastDay));
    FetchAnsweredByList();
    fetchCallLogsData(firstDay, lastDay, Region, User);
  }, []);
  const FetchAnsweredByList = async () => {
    axios
      .get(baseUrl + "FetchAnsweredByList", requestOptions)
      .then((response) => {
        setAnsweredByUserList(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const fetchCallLogsData = async (firstDay, lastDay, Region, User) => {
    setIsLoading(true);
    var filterModel = {
      startDate: moment(firstDay).format("YYYY-MM-DD"),
      endDate: moment(lastDay).format("YYYY-MM-DD"),
      region: Region,
      user: User.toString(),
    };

    axios
      .post(baseUrl + "FetchCallLogs", filterModel, requestOptions)
      .then((response) => {
        setDataSource(response.data.callLogs);
        setTotalCalls(response.data.totalCalls);
        setUniqueCalls(response.data.uniqueCalls);
        setCallsToVoiceMail(response.data.callsToVoiceMail);
        setIsLoading(false);
        if (CurentMonthDataSource.length <= 0) {
          setCurentMonthDataSource(response.data.callLogs);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const sort = (sortby) => {
    var tempUserList = dataSource;
    if (sortby == "voiceEmail") {
      if (nameSortOrder == "desc") {
        tempUserList.sort(function (x, y) {
          let a = x.voicemail.toUpperCase(),
            b = y.voicemail.toUpperCase();
          return a == b ? 0 : a > b ? 1 : -1;
        });
        setNameSortOrder("asc");
      } else {
        tempUserList.sort(function (x, y) {
          let a = x.voicemail.toUpperCase(),
            b = y.voicemail.toUpperCase();
          return a == b ? 0 : a > b ? -1 : 1;
        });
        setNameSortOrder("desc");
      }
    }
    setDataSource([...tempUserList]);
  };

  function checkDateValidation(startDate, endDate) {
    // check the dates
    if (new Date(startDate) > new Date(endDate) || new Date(endDate) < new Date(startDate)) {
      // set date error validation true
      setconfirmationMessage("End date always greater then end date");
      setconfirmationPopupVisible(true);

      return false;
    }
    var oneDay = 24 * 60 * 60 * 1000;
    var diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    if (diffDays >= 365) {
      setconfirmationMessage(
        "Your specified end date cannot be more than 1 year from the start date."
      );
      setconfirmationPopupVisible(true);
      return false;
    }
    return true;
  }
  const handleStartDate = (e) => {
    setStartDate(e);

    if (checkDateValidation(e, EndDate) == true) {
      fetchCallLogsData(e, EndDate, Region, User);
    }
  };
  const handleEndDate = (e) => {
    setEndDate(e);

    if (checkDateValidation(StartDate, e) == true) {
      fetchCallLogsData(StartDate, e, Region, User);
    }
  };
  const handleRegion = (e) => {
    setRegion(e);
    fetchCallLogsData(StartDate, EndDate, e, User);
  };

  const handleUser = (e) => {
    setUser(e);
    fetchCallLogsData(StartDate, EndDate, Region, e);
  };

  const HandleHidePopup = () => {
    setconfirmationPopupVisible(false);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "callDate",
      key: "callDate",
      width: "12%",
      //render: (text) => <span className="table__element">{text}</span>,
      render: (text, record) => (
        <>
          <span className="table__element">{text}&nbsp;&nbsp;&nbsp;
            {{
              2: <Tag color='volcano'>SA</Tag>,
              3: <Tag color='geekblue'>AH</Tag>,
              4: <Tag color='green'>H</Tag>,
            }[record.shiftStatus]
            }
          </span>
        </>
      ),
    },
    {
      title: "Time",
      dataIndex: "callTime",
      key: "callTime",
      width: "12%",
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Incoming Number",
      dataIndex: "incomingNumer",
      key: "incomingNumer",
      width: "12%",
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      width: "8%",
      render: (text, record) => (
        <>
          {
            (record.shiftStatus === 2) ?
              (record.regionRequested === '100') ?
                <>
                  <span className="table__element">Youth Program</span>&nbsp;&nbsp;&nbsp;<Tag color='volcano'>{text}</Tag>
                </> :
                <>
                  {record.regionRequested}&nbsp;&nbsp;&nbsp;<Tag color='volcano'>{text}</Tag>
                </> :
              (record.region === '100') ?
                <>
                  <span className="table__element">Youth Program</span>
                </> :
                <span className="table__element">{text}</span>
          }
        </>
      ),
    },
    {
      title: "Answered",
      dataIndex: "answered",
      key: "answered",
      width: "8%",
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Primary On Call",
      dataIndex: "primaryOnCall",
      key: "primaryOnCall",
      width: "15%",
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Answered By",
      dataIndex: "answeredBy",
      key: "answeredBy",
      width: "15%",
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: (
        <span className="employee_column">
          Voicemail
          <img className="menu__icon" src={menu} alt="menu" onClick={() => sort("voiceEmail")} />
        </span>
      ),
      width: "12%",
      dataIndex: "voicemail",
      key: "voicemail",
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Voicemail Duration",
      dataIndex: "voicemailDuration",
      key: "voicemailDuration",
      width: "6%",
      //render: (text) => <span className="table__element">{text}</span>,
      render: (text, record) => (
        <>
          {
            (record.voicemail === "No") ?
              <>
                <span className="table__element"></span>
              </> :
              <span className="table__element">{text}s</span>
          }
        </>
      ),
    },
  ];
  const Excelheaders = [
    { label: "Date", key: "callDate" },
    { label: "Time", key: "callTime" },
    { label: "Incoming Number", key: "incomingNumer" },
    { label: "Region", key: "region" },
    { label: "Answered", key: "answered" },
    { label: "Primary On Call", key: "primaryOnCall" },
    { label: "Answered By", key: "answeredBy" },
    { label: "Voicemail", key: "voicemail" },
    { label: "Voicemail Duration", key: "voicemailDuration" },
  ];

  const content = (
    <div>
      <p className="popover__content">
        <CSVLink
          data={CurentMonthDataSource}
          headers={Excelheaders}
          filename={"Current-Month-" + moment(StartDate).format("YYYY-MM") + ".csv"}
        >
          Current Month
        </CSVLink>
      </p>
      <p className="popover__content">
        <CSVLink
          data={dataSource}
          headers={Excelheaders}
          filename={
            "Filtered-Data-" +
            moment(StartDate).format("YYYY-MM-DD") +
            "-" +
            moment(EndDate).format("YYYY-MM-DD") +
            ".csv"
          }
        >
          Filtered Data
        </CSVLink>
      </p>
    </div>
  );
  return (
    <div>
      {isLoding ? (
        <Loader />
      ) : (
        <div className="employee">
          <Heading
            downloadBtn
            heading="CALL ANALYTICS"
            contents={[
              <Popover
                content={content}
                placement="bottomRight"
                trigger="click"
                className="popover__filter"
              >
                <i className="fas fa-arrow-to-bottom"></i>
              </Popover>,
            ]}
          />
          <div className="dashboard__form">
            <div className="dashboard__info">
              <div className="dashboard__callCount">
                <p>Total Calls:</p>
                <h1>{totalCalls}</h1>
              </div>
              <div className="dashboard__callCount">
                <p>Unique Calls:</p>
                <h1>{uniqueCalls}</h1>
              </div>
              <div className="dashboard__callCount">
                <p>Calls To Voicemail:</p>
                <h1>{callsToVoiceMail}</h1>
              </div>
            </div>
            <div className="dashboard__rightInfo">
              <div>
                <label>Start Date</label>
                <DatePicker
                  onChange={handleStartDate}
                  format={dateFormat}
                  value={StartDate}
                  className="dashboard__datepicker"
                />
              </div>
              <div>
                <label>End Date</label>
                <DatePicker
                  onChange={handleEndDate}
                  format={dateFormat}
                  value={EndDate}
                  className="dashboard__datepicker"
                />
              </div>
              <div>
                <label>Region</label>
                <Select
                  style={{ width: 150 }}
                  placeholder="Region"
                  name="region"
                  value={Region}
                  onChange={handleRegion}
                  className="employee_select"
                >
                  <Option value="0">All</Option>
                  <Option value="1">Region 1</Option>
                  <Option value="2">Region 2</Option>
                  <Option value="3">Region 3</Option>
                  <Option value="4">Region 4</Option>
                  <Option value="5">Region 5</Option>
                  <Option value="6">Region 6</Option>
                  <Option value="7">Region 7</Option>
                  <Option value="8">Region 8</Option>
                  <Option value="9">Region 9</Option>
                  <Option value="10">Region 10</Option>
                  <Option value="100">Youth Program</Option>
                </Select>
              </div>
              <div>
                <label>User</label>
                <Select
                  showSearch
                  style={{ width: 150 }}
                  placeholder="User"
                  name="user"
                  value={User}
                  onChange={handleUser}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  className="employee_select"
                >
                  <Option id="userdropdown" value="0">
                    All
                  </Option>
                  {AnsweredByUserList.map((e, key) => {
                    return (
                      <Option key={key + 1} value={e.key}>
                        {e.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>
          <div className="employeeTable__wrapper dashboard__table">
            <Table
              dataSource={dataSource}
              columns={columns}
              rowClassName="editable-row"
              scroll={{ x: 1350 }}
              pagination={false}
            />
          </div>
          <ConfirmationModal
            title="Error !"
            error={true}
            errorStyle={true}
            visible={confirmationPopupVisible}
            buttonName="FIX DATE"
            message={confirmationMessage}
            hidePopup={HandleHidePopup}
            PerformAction={HandleHidePopup}
          />
        </div>
      )}
    </div>
  );
}
export default Dashboard;
