import React, { Component } from "react";
import Heading from "../Heading";
import Tabs from "../Tabs";
import DayWiseShift from "../DayWiseShift";
import "./styles.css";
import config from "../../config/config";
import axios from "axios";
import { authHeader } from "../../actions/auth-header";
import ConfirmationModal from "../ConfirmationModal";

const requestOptions = {
    headers: authHeader(),
};

class RegionWise extends Component {
  constructor(props) {
    super(props);
    this.state = {
        tab: 2,
        holidayData: [],
        tempTab: 2
    };
    this.changeTab = this.changeTab.bind(this);
    }
    componentDidMount() {
        localStorage.setItem("isUnsaveToggle", false);
        axios
            .get(config.apiUrl + "HolidayHours/GetHolidayHours", requestOptions)
            .then((response) => {              
                this.setState({ holidayData: response.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
   

    hidePopup = () => {
        this.setState({ confirmationPopupVisible: false });
    }
    performAction = () => {
        localStorage.setItem("isUnsaveToggle", false);
        this.setState({ tab: this.state.tempTab, confirmationPopupVisible: false });
    }
    changeTab(tab) {
        var temp = localStorage.getItem("isUnsaveToggle");
        if (temp == "true") {
            this.setState({ confirmationPopupVisible: true, tempTab: tab });
        }
        else
    this.setState({ tab });
  }
  render() {
      const { tab } = this.state;
      var regionDisplayName = "REGION " + this.props.region
      if (this.props.region == '100') {
        regionDisplayName = "YOUTH PROGRAM"
      }
      var regionName = regionDisplayName
    return (
        <div className="regions">
            <Heading heading={regionName} />
        <div className="weektab__wrap">
          <Tabs
            tabstyles="weekTabs"
            region="Monday"
            onClick={() => this.changeTab(2)}
            selected={tab === 2}
          />
          <Tabs
            tabstyles="weekTabs"
            region="Tuesday"
            onClick={() => this.changeTab(3)}
            selected={tab === 3}
          />
          <Tabs
            tabstyles="weekTabs"
            region="Wednesday"
            onClick={() => this.changeTab(4)}
            selected={tab === 4}
          />
          <Tabs
            tabstyles="weekTabs"
            region="Thursday"
            onClick={() => this.changeTab(5)}
            selected={tab === 5}
          />
          <Tabs
                    tabstyles="weekTabs"
                    region="Friday"
                    onClick={() => this.changeTab(6)}
                    selected={tab === 6}
          />
        </div>
        <div>
                {tab === 2 && !this.state.holidayData.monday && <DayWiseShift regionId={this.props.region} day={ tab}/>}
                {tab === 3 && !this.state.holidayData.tuesday && <DayWiseShift regionId={this.props.region} day={tab}/>}
                {tab === 4 && !this.state.holidayData.wednesday && <DayWiseShift regionId={this.props.region} day={tab}/>}
                {tab === 5 && !this.state.holidayData.thursday && <DayWiseShift regionId={this.props.region} day={tab} />}
                {tab === 6 && !this.state.holidayData.friday && <DayWiseShift regionId={this.props.region} day={tab} />}

                {tab === 2 && this.state.holidayData.monday && (
                    <div className="shifts__holiday">
                        <h1>Today is an all-staff holiday.</h1>
                        <p>If this is incorrect, please update this week's holiday hours.</p>
                    </div>
                )
}
                {tab === 3 && this.state.holidayData.tuesday && (
                    <div className="shifts__holiday">
                        <h1>Today is an all-staff holiday.</h1>
                        <p>If this is incorrect, please update this week's holiday hours.</p>
                    </div>
                )
}
                {tab === 4 && this.state.holidayData.wednesday && (
                    <div className="shifts__holiday">
                        <h1>Today is an all-staff holiday.</h1>
                        <p>If this is incorrect, please update this week's holiday hours.</p>
                    </div>
                )
}
                {tab === 5 && this.state.holidayData.thursday && (
                    <div className="shifts__holiday">
                        <h1>Today is an all-staff holiday.</h1>
                        <p>If this is incorrect, please update this week's holiday hours.</p>
                    </div>
                )
}
                {tab === 6 && this.state.holidayData.friday && (
                    <div className="shifts__holiday">
                        <h1>Today is an all-staff holiday.</h1>
                        <p>If this is incorrect, please update this week's holiday hours.</p>
                    </div>
                )
}
            </div>
            <ConfirmationModal
                title={"Confirm"}
                visible={this.state.confirmationPopupVisible}
                buttonName={"OK"}
                message={"Your have unsaved changes, are you sure you want to leave"}
                hidePopup={this.hidePopup}
                PerformAction={this.performAction}
            />
      </div>
    );
  }
}
export default RegionWise;
