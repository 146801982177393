import React, { Component } from "react";
import { Modal, Button, Form, Select, Input, InputNumber, Checkbox } from "antd";
import { useToasts } from "react-toast-notifications";
import config from "../../config/config";
import { authHeader } from "../../actions/auth-header";
import "./styles.css";
import axios from "axios";

const Option = Select.Option;
const baseUrl = config.apiUrl + "User/";
const requestOptions = {
  headers: authHeader(),
};

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideModal: false,
      loading: false,
      receiveeVoiceEmails: true,
      isAnAdmin: false,
    };
  }

  deleteItem(key) {
    axios
      .delete(baseUrl + "DeleteEmployee/" + key, requestOptions)
      .then(function (response) {
        console.log(response);
        setTimeout(() => {
          this.props.hidePopup(true);
          // this.props.addToast("Employee Deleted successfully", { appearance: "success" });
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const {
      visible,
      hidePopup,
      buttonName,
      message,
      PerformAction,
      title,
      errorStyle,
      error,
    } = this.props;

    const { loading } = this.state;
    return (
      <div>
        <Modal
          title={title}
                visible={visible}
                closable={false}
          className="ant-modal-body"
          footer={[
            !error && (
              <Button key="back" onClick={hidePopup} size="large" className="common_btn cancel_btn">
                CANCEL
              </Button>
            ),
            <Button
              key="submit"
              type="primary"
              size="large"
              loading={loading}
              onClick={PerformAction}
              className={`${errorStyle ? "errorBtn" : "save_btn"} common_btn`}
            >
              {buttonName}
            </Button>,
          ]}
        >
          <span className="message__heading">{message}</span>
        </Modal>
      </div>
    );
  }
}
export default withToast(ConfirmationModal);
