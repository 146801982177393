import React, { Component } from "react";
import { Modal, Button, Form, Select, Input, InputNumber, Checkbox } from "antd";
import { useToasts } from "react-toast-notifications";
import config from "../../config/config";
import { authHeader } from "../../actions/auth-header";
import ConfirmationModal from "../ConfirmationModal";
import InputMask from "react-input-mask";
import "./styles.css";
import axios from "axios";
import lodash from "lodash";

const Option = Select.Option;
const baseUrl = config.apiUrl + "User/";
const requestOptions = {
    headers: authHeader(),
};

function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts();
        return <Component {...props} {...toastFuncs} />;
    };
}

class EditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideModal: false,
            loading: false,
            name: "",
            phoneNumber: "",
            email: "",
            region: "",
            role: "",
            receiveVoiceEmails: false,
            isAnAdmin: false,
            deleteUserKey: "",
            confirmationPopupVisible: false,
            confirmationButton: "",
            confirmMessage: "",
            secondaryRegions: [],
            isSpeakspanish: false,
            editUserData: [],
        };
    }
    componentDidMount() {
        this.setUserData(this.props.userModal);
    }
    componentDidUpdate(prevProps) {
        const prevPropsValues = {
            userModal: prevProps.userModal,
        };
        const currentPropsValues = {
            userModal: this.props.userModal,
        };

        const prevPropsVisible = {
            visible: prevProps.visible,
        };
        const currentPropsVisible = {
            visible: this.props.visible,
        };

        if (!lodash.isEqual(prevPropsValues, currentPropsValues)||!lodash.isEqual(prevPropsVisible, currentPropsVisible)) {
            this.setUserData(this.props.userModal);
        }


        if (!lodash.isEqual(prevPropsVisible, currentPropsVisible) && this.props.visible == false) {
           this.setState({ secondaryRegions: [] })
        }
    }
    setUserKey = (key) => {
        this.setState({ deleteUserKey: key, confirmationPopupVisible: true, confirmationButton: "DELETE", confirmMessage: "Are you sure you want to delete this user?" });
    };

    PerformAction = () => {
        if (this.state.confirmationButton == "DELETE") {
            axios
                .delete(baseUrl + "DeleteEmployee/" + this.state.deleteUserKey, requestOptions)
                .then((response) => {
                    this.setState({ confirmationPopupVisible: false });
                    this.props.hideForm();                  
                })
                .catch(function (error) {
                    console.log(error);
                });
          
        }
        if (this.state.confirmationButton == "CONFIRM") {
            this.setState({ isAnAdmin: true, confirmationPopupVisible: false, isAnAdmin: false });


        }


    };
    hideDeletePopup = () => {

        this.setState({ confirmationPopupVisible: false });
    };
    handleName = (e) => {
        e.preventDefault();
        this.setState({ name: e.target.value });
    };
    handleNumber = (value) => {
      //  console.log(value.target.value.replace(/[^0-9]+/g, ""),"anjali")
        this.setState({ phoneNumber: value.target.value.replace(/[^0-9]+/g, "") });
    };
    handleEmail = (e) => {
        e.preventDefault();
        this.setState({ email: e.target.value });
    };
    handleRegion = (value) => {
        this.setState({ region: value });
    };
    handleRole = (value) => {
        this.setState({ role: value });
    };
    handleReceiveVoiceEmails = (value) => {
        this.setState({ receiveVoiceEmails: value.target.checked });
    };
    handleIsAnAdmin = (value) => {
        if (!value.target.checked) {
            this.setState({ confirmationPopupVisible: true, confirmationButton: "CONFIRM", confirmMessage: "Are you sure you want to remove admin access for this user?" });
        }
        else
            this.setState({ isAnAdmin: value.target.checked });
    };
    handleIsSpeakSpanish = (value) => {
        this.setState({ isSpeakspanish: value.target.checked });
    };

    handleSecondaryRegion = (e, value) => {
        if (this.state.secondaryRegions.includes(value)) {
            this.state.secondaryRegions.splice(this.state.secondaryRegions.indexOf(value), 1);
        } else this.state.secondaryRegions.push(value);
        this.setState({ secondaryRegions: this.state.secondaryRegions });
    };

    setUserData = (userData) => {
        this.setState({
            name: userData.name,
            phoneNumber: userData.phoneNumber,
            email: userData.email,
            region: userData.region,
            role: userData.role,
            receiveVoiceEmails: userData.receiveVoiceEmails,
            isAnAdmin: userData.isAnAdmin,
            isSpeakspanish: userData.isSpeekSpanish,
            secondaryRegions: userData.secondaryRegions,
        });

        console.log(this.state.name);
    };

    validate = () => {
        var isDublicateEmail = false;
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        )
        if (this.state.name === null || this.state.name === "") {
            this.props.addToast("User Name Required!", { appearance: "error" });
            return false;
        }
        if (this.state.phoneNumber === null || this.state.phoneNumber === "") {
            this.props.addToast("User Number Required!", { appearance: "error" });
            return false;
        }
        if (this.state.email === null || this.state.email === "") {
            this.props.addToast("Email Address Required!", { appearance: "error" });
            return false;
        }
        else if (!pattern.test(this.state.email)) {
            this.props.addToast("Please Enter correct Email!", { appearance: "error" });
            return false;
        }
        if (this.state.region === null || this.state.region === "") {
            this.props.addToast("User Region Required!", { appearance: "error" });
            return false;
        }
        if (this.state.role === null || this.state.role === "") {
            this.props.addToast("User Role Required!", { appearance: "error" });
            return false;
        }
        if (this.state.phoneNumber != null) {
            //  const numOfDig = this.state.phoneNumber.toString();
            if (this.state.phoneNumber.toString().length >= 11 || this.state.phoneNumber.toString().length <= 9) {
                this.props.addToast("User Number must be 10 digit!", {
                    appearance: "error",
                });
                return false;
            }
        }
        this.props.userList && this.props.userList.length > 0 && this.props.userList.map((e) => {
            if (e.email == this.state.email && e.key != this.props.userModal.key) {
                isDublicateEmail = true;
            }
        })
        if (isDublicateEmail) {
            this.props.addToast("User Email Already Exist!", { appearance: "error" });
            return false;
        }
        return true;
    };

    handleOk = (e) => {
        if (this.validate()) {


            const newUrl = "UpdateEmployee/";
            axios.post(
                baseUrl + newUrl,
                {
                    key: e,
                    name: this.state.name,
                    phoneNumber: this.state.phoneNumber.toString(),
                    email: this.state.email,
                    region: this.state.region,
                    role: this.state.role,
                    receiveVoiceEmails: this.state.receiveVoiceEmails,
                    isAnAdmin: this.state.isAnAdmin,
                    isSpeakspanish: this.state.isSpeakspanish,
                    secondaryRegions: this.state.secondaryRegions,
                },
                requestOptions
            ).then((res) => {
                this.props.addToast("Submitted successfully", { appearance: "success" });
                this.setState({ loading: false, hideModal: false });
                this.props.hideForm(true);
            });
          
          //  this.setState({ loading: true });
       


                //window.location.reload(false);
         
        }
    };

    beforeMaskedStateChangeFunction = (e) => {
        //let { value } = nextState;
        //if (value.endsWith("/")) {
        //    value = value.slice(0, -1);
        //}
        console.log(e)
    }

    render() {
        const { visible, hideForm, userModal, totalRegion, handleUserName } = this.props;

        var regionData = [];
        regionData = totalRegion.data;
        //  this.setUserData(userModal);
        if (regionData) {
            regionData.map((e) => {
                if (this.state.secondaryRegions.includes(e.regionId)) e.isSelected = true;
                else e.isSelected = false;
            });
        }
        const {
            loading,
            name,
            email,
            phoneNumber,
            region,
            role,
            isSpeakspanish,
            isAnAdmin,
            receiveVoiceEmails,
            secondaryRegions,
        } = this.state;
        return (
            <div>
                <Modal
                    title={"EDIT " + userModal.name}
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={hideForm}
                    width="60%"
                    footer={[
                        <span onClick={() => this.setUserKey(userModal.key)} className="user__dlt" key={userModal.key}>
                            <i className="fas fa-trash" />
                            <span>DELETE THIS USER</span>
                        </span>,
                        <Button key="back" onClick={hideForm} size="large" className="common_btn cancel_btn">
                            CANCEL
            </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            size="large"
                            loading={loading}
                            onClick={() => this.handleOk(userModal.key)}
                            className="common_btn save_btn"
                        >
                            SAVE CHANGES
            </Button>,
                    ]}
                >
                    <Form layout="vertical" className="edit__form">
                        <div>
                            <Form.Item
                                label="User Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Employee Name Required!",
                                    },
                                ]}
                                className="edit__field"
                            >
                                <Input
                                    className="modal__input"
                                    placeholder="Full Name"
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={this.handleName}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Phone Number"
                                rules={[
                                    {
                                        required: true,
                                        message: "Employee Phone Number Required!",
                                    },
                                ]}
                                className="edit__field"
                            >
                                <InputMask
                                    className="modal__number_input"
                                    placeholder="Phone Number"
                                    type="text"
                                    name="phoneNumber"
                                    value={this.state.phoneNumber}
                                    onChange={this.handleNumber}
                                    mask="999-999-9999"
                                    //beforeMaskedStateChange={this.beforeMaskedStateChangeFunction} 
                                />

                            </Form.Item>
                            <Form.Item
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Employee Email Required!",
                                    },
                                ]}
                                className="edit__field"
                            >
                                <Input
                                    className="modal__input"
                                    placeholder="Email Address"
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={this.handleEmail}
                                />
                            </Form.Item>
                            <div className="edit__formRegion">
                                <Form.Item
                                    label="Primary Region"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Email Address Required!",
                                        },
                                    ]}
                                    className="edit__field"
                                >
                                    <Select
                                        placeholder="Region"
                                        name="region"
                                        onChange={this.handleRegion}
                                        value={region}
                                    >
                                        {/* <Option value={""}>Region</Option> */}
                                        <Option value="Region 1">Region 1</Option>
                                        <Option value="Region 2">Region 2</Option>
                                        <Option value="Region 3">Region 3</Option>
                                        <Option value="Region 4">Region 4</Option>
                                        <Option value="Region 5">Region 5</Option>
                                        <Option value="Region 6">Region 6</Option>
                                        <Option value="Region 7">Region 7</Option>
                                        <Option value="Region 8">Region 8</Option>
                                        <Option value="Region 9">Region 9</Option>
                                        <Option value="Region 10">Region 10</Option>
                                        <Option value="Youth Program">Youth Program</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Role"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Employee Role Required!",
                                        },
                                    ]}
                                    className="edit__field"
                                >
                                    <Select placeholder="Role" name="role" onChange={this.handleRole} value={role}>
                                        {/* <Option value={""}>Role> */}
                                        <Option value="Navigator">Navigator</Option>
                                        <Option value="Backup1">Coach</Option>
                                        <Option value="Backup2">Coordinator</Option>
                                        <Option value="Backup3">Regional Director</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div>
                            <div className="edit__checkbox">
                                <label>User Settings</label>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Checkbox
                                        name="receiveVoiceEmails"
                                        onChange={this.handleReceiveVoiceEmails}
                                        checked={receiveVoiceEmails}
                                        className="user__checkbox"
                                    >
                                        User should receive voicemails
                  </Checkbox>
                                </Form.Item>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Checkbox
                                        type="Checkbox"
                                        name="isAnAdmin"
                                        onChange={this.handleIsAnAdmin}
                                        checked={isAnAdmin}
                                        className="user__checkbox"
                                    >
                                        User is an admin
                  </Checkbox>
                                </Form.Item>

                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Checkbox
                                        type="Checkbox"
                                        name="isSpeakspanish"
                                        onChange={this.handleIsSpeakSpanish}
                                        checked={isSpeakspanish}
                                        className="user__checkbox"
                                    >
                                        User speaks Spanish
                  </Checkbox>
                                </Form.Item>
                            </div>
                            <div className="edit__checkbox">
                                <label>Eligible Regions</label>
                                <div className="edit__eligibleRegion">
                                    <div>
                                        {regionData
                                            ? regionData.map((event, key) => {
                                                if (key < 5) {
                                                    return (
                                                        <Form.Item style={{ marginBottom: 0 }} key={key}>
                                                            <Checkbox
                                                                type="Checkbox"
                                                                name="regions"
                                                                disabled={event.regionName == region}
                                                                onChange={(e) =>
                                                                    this.handleSecondaryRegion(e.target.checked, event.regionId)
                                                                }
                                                                checked={secondaryRegions.includes(event.regionId) || event.regionName == region}
                                                                className="user__checkbox"
                                                            >
                                                                {event.regionName}
                                                            </Checkbox>
                                                        </Form.Item>
                                                    );
                                                } else return null;
                                            })
                                            : ""}
                                    </div>
                                    <div>
                                        {regionData
                                            ? regionData.map((event, key) => {
                                                if (key > 4) {
                                                    return (
                                                        <Form.Item style={{ marginBottom: 0 }} key={key}>
                                                            <Checkbox
                                                                type="Checkbox"
                                                                name="regions"
                                                                disabled={event.regionName == region}
                                                                onChange={(e) =>
                                                                    this.handleSecondaryRegion(e.target.checked, event.regionId)
                                                                }
                                                                checked={secondaryRegions.includes(event.regionId) || event.regionName == region}
                                                                className="user__checkbox"
                                                            >
                                                                {event.regionName}
                                                            </Checkbox>
                                                        </Form.Item>
                                                    );
                                                } else return null;
                                            })
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal>
                <ConfirmationModal
                    title="Please Confirm!"
                    // error
                    visible={this.state.confirmationPopupVisible}
                    buttonName={this.state.confirmationButton}
                    message={this.state.confirmMessage}
                    hidePopup={this.hideDeletePopup}
                    PerformAction={this.PerformAction}
                // errorStyle="errorBtn"
                />
            </div>
        );
    }
}
export default withToast(EditModal);
