import React from "react";
import { Switch, Route } from "react-router-dom";
import Regions from "../components/Regions";
import Employees from "../components/Employees";
import Dashboard from "../components/Dashboard";
import Schedule from '../components/Schedule';

function AuthRouter() {
  return (
    <Switch>
      <Route path="/schedule" exact component={Schedule} />
      <Route path="/users" exact component={Employees} />
      <Route path="/dashboard" exact component={Dashboard}/>
    </Switch>
  );
}

export default AuthRouter;
