import React, { Component } from "react";
import { Form, Input, InputNumber, Select } from "antd";
import "./styles.css";
const Option = Select.Option;

class EditableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phoneNumber: "",
      region: null,
      role: null,
      email: "",
    };
  }

  componentDidMount() {
      const data = this.props.record;
    if (data != null) {
      this.setState({ name: data.name });
      this.setState({ phoneNumber: data.phoneNumber });
      this.setState({ region: data.region });
      this.setState({ role: data.role });
      this.setState({ email: data.email });
    }
  }

  handleName = (e) => {
      e.preventDefault();

    this.setState({ name: e.target.value });
    this.props.record.name = e.target.value;
  };
  handleNumber = (value) => {
    this.setState({ phoneNumber: value });
    this.props.record.phoneNumber = value;
  };
  handleRegion = (value) => {
    this.setState({ region: value });
    this.props.record.region = value;
  };
  handleRole = (value) => {
    this.setState({ role: value });
    this.props.record.role = value;
    };

    handleEmail = (e) => {
        e.preventDefault();
        this.setState({ email: e.target.value });
        this.props.record.email = e.target.value;
    };
  

  getEditModel = () => {
    this.props.record.name = this.state.name;
    this.props.record.phoneNumber = this.state.phoneNumber;
    this.props.record.region = this.state.region;
    this.props.record.role = this.state.role;
    this.props.record.email = this.state.email;
  };

  getInput = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    inputType,
    children,
    ...restProps
  }) => {
    switch (this.props.inputType) {
      case "number":
        return (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <InputNumber
              className="input_field"
              placeholder="Phone Number"
              name="phoneNumber"
              value={this.state.phoneNumber}
              onChange={this.handleNumber}
              formatter={(value) => value}
              parser={(value) => value}
              type="number"
            />
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item style={{ margin: 0 }} name={dataIndex}>
            <Select
              style={{ width: 150 }}
              placeholder="Region"
              name="region"
              value={this.state.region}
              onChange={this.handleRegion}
              className="employee_select"
            >
              <Option value="Region 1">Region 1</Option>
              <Option value="Region 2">Region 2</Option>
              <Option value="Region 3">Region 3</Option>
              <Option value="Region 4">Region 4</Option>
              <Option value="Region 5">Region 5</Option>
              <Option value="Region 6">Region 6</Option>
              <Option value="Region 7">Region 7</Option>
              <Option value="Region 8">Region 8</Option>
              <Option value="Region 9">Region 9</Option>
              <Option value="Region 10">Region 10</Option>
              <Option value="Youth Program">Youth Program</Option>
            </Select>
          </Form.Item>
        );
      case "select1":
        return (
          <Form.Item name={dataIndex}>
            <Select
              style={{ width: 150 }}
              placeholder="Role"
              name="role"
              value={this.state.role}
              onChange={this.handleRole}
              className="employee_select"
            >
              <Option value="Navigator">Navigator</Option>
              <Option value="Coach">Coach</Option>
              <Option value="Coordinator">Coordinator</Option>
              <Option value="Regional Director">Regional Director</Option>
            </Select>
          </Form.Item>
        );
      case "email":
        return (
          <Form.Item
            name={dataIndex}
            rules={[{ required: true, message: `Please Input ${title}!` }]}
          >
            <Input
              type="email"
              className="input_field"
              placeholder="Email"
              name="email"
              value={this.state.email}
              onChange={this.handleEmail}
            />
          </Form.Item>
        );

      default:
        return (
          <Form.Item
            name={dataIndex}
            rules={[{ required: true, message: `Please Input ${title}!` }]}
          >
            <Input
              type="text"
              className="input_field"
              placeholder="Full Name"
              name="name"
              value={this.state.name}
              onChange={this.handleName}
            />
          </Form.Item>
        );
    }
  };
  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? this.getInput(record, dataIndex, title) : restProps.children}
      </td>
    );
  }
}

export default EditableCell;
