import React, { useState, useEffect} from "react";
import MainLayout from "./layout/MainLayout";
import AuthRouter from "./routers/AuthRouter";
import { store } from "./actions/store";
import { Provider } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import "antd/dist/antd.css";
import "./App.css";

function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const user=localStorage.getItem('user');
    if(user==='' || user==null){
     setData(false);
    }
    else{
      setData(true);
    }
}, [])//componentDidMount



  return (
    <div className="App">
      <ToastProvider autoDismiss={true}>
       <AuthRouter />
       </ToastProvider>
      <Provider store={store}>
      <ToastProvider autoDismiss={true}>
      {data && <MainLayout />}
      </ToastProvider>
      </Provider>
    </div>
  );
}

export default withRouter(App);
