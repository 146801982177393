import React from "react";
import Header from "../components/Header";
import AppRouter from "../routers/AppRouter";
import Footer from "../components/Footer";

import "./styles.css";

function MainLayout() {
  return (
    <div className="container">
      <Header />
      <AppRouter />
      <Footer />
    </div>
  );
}
export default MainLayout;
