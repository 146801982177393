import React, { useState, useEffect } from "react";
import { Table, Space, Popconfirm, Button, Divider, Select, Form } from "antd";
import config from "../../config/config";
import "antd/dist/antd.css";
import Heading from "../Heading";
// import "../../../node_modules/antd/dist/antd.css";
import * as actions from "../../actions/dEmployees";
import { useToasts } from "react-toast-notifications";
import { authHeader } from "../../actions/auth-header";
import { connect } from "react-redux";
import "./styles.css";
import axios from "axios";
import Accordian from "../Accordian";
const Option = Select.Option;

const baseUrl = config.apiUrl + "Region/";
const requestOptions = {
  headers: authHeader(),
};

const EditableCell = ({ editing, dataIndex, title, record, index, children, ...restProps }) => {
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    if (typeof record != "undefined") {
      axios
        .get(baseUrl + "GetEmployeesByRegionId/" + record.regionId, requestOptions)
        .then(function (response) {
          setEmployees(response.data);
          console.log(response.data);
        });
    }
  }, []);

  const handleName = (key) => {
    console.log(key);
    console.log(record);
    console.log(restProps);
    record.personId = key;
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Select style={{ width: 150 }} onChange={handleName}>
            {employees.map((e, key) => {
              return (
                <Option key={key} value={e.personId}>
                  {e.name + " (" + e.role + ")"}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = ({ classes, ...props }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [data7, setData7] = useState([]);
  const [data8, setData8] = useState([]);
  const [data9, setData9] = useState([]);
  const [data100, setData100] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  //toast msg.
  const { addToast } = useToasts();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user === "" || user == null) {
      window.location.href = "/login";
    }
    axios
      .get(baseUrl + "GetRegions", requestOptions)
      .then(function (response) {
        // console.log(response.data);
        setData(response.data[0]);
        setData1(response.data[1]);
        setData2(response.data[2]);
        setData3(response.data[3]);
        setData4(response.data[4]);
        setData5(response.data[5]);
        setData6(response.data[6]);
        setData7(response.data[7]);
        setData8(response.data[8]);
        setData9(response.data[9]);
        setData100(response.data[10]);
        setRegionData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    props.fetchAllRegion();
  }, []); //componentDidMount

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    props.fetchRegionPerson(record.regionId);
    form.setFieldsValue({
      days: "",
      name: "",
      number: "",
      email: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const newData = [...regionData];
      const index = newData.findIndex((item) => key === item.regionModel.key);
      const row = await form.validateFields();

      for (let i = 0; i < 10; i++) {
        const data = newData[i].regionModel;
        const indexx = data.findIndex((item) => key === item.key);
        const newUrl = "EditRegion/";
        if (indexx > -1) {
          const item = data[indexx];
          axios.put(
            baseUrl + newUrl,
            {
              key: item.key,
              name: item.name,
              number: item.number,
              days: item.days,
              regionId: item.regionId,
              personId: item.personId,
            },
            requestOptions
          );
        }
      }
      addToast("Update successfully", { appearance: "success" });
      setEditingKey("");
      setTimeout(() => {
        axios
          .get(baseUrl + "GetRegions", requestOptions)
          .then(function (response) {
            // console.log(response.data);
            setData(response.data[0]);
            setData1(response.data[1]);
            setData2(response.data[2]);
            setData3(response.data[3]);
            setData4(response.data[4]);
            setData5(response.data[5]);
            setData6(response.data[6]);
            setData7(response.data[7]);
            setData8(response.data[8]);
            setData9(response.data[9]);
            setData100(response.data[10]);
            setRegionData(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }, 1000);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
      render: (text) => <span className="table__element table__element--bold">{text}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "number",
      key: "number",
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      render: (text) => <span className="table__element">{text}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <Space size="middle">
            <div className="editable-row-operations">
              {editable ? (
                <span>
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <Button size="large" className="common_btn cancel_btn" ghost>
                      Cancel
                    </Button>
                  </Popconfirm>
                  <Divider type="vertical" />
                  <Button
                    onClick={() => save(record.key)}
                    className="common_btn save_btn"
                    type="primary"
                    size="large"
                  >
                    Save
                  </Button>
                </span>
              ) : (
                <span disabled={editingKey !== ""} onClick={() => edit(record)}>
                  <i className="fas fa-pen penIcon"></i>
                </span>
              )}
            </div>
          </Space>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <div className="regions">
      <Heading heading="EDIT CALLS BY REGION" />
      <div className="accordian__wrapper">
        <Accordian
          title="Region 1"
          index="1"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
        <Accordian
          title="Region 2"
          index="2"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data1.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
        <Accordian
          title="Region 3"
          index="3"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data2.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
        <Accordian
          title="Region 4"
          index="4"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data3.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
        <Accordian
          title="Region 5"
          index="5"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data4.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
        <Accordian
          title="Region 6"
          index="6"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data5.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
        <Accordian
          title="Region 7"
          index="7"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data6.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
        <Accordian
          title="Region 8"
          index="8"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data7.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
        <Accordian
          title="Region 9"
          index="9"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data8.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
        <Accordian
          title="Region 10"
          index="10"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data9.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
        <Accordian
          title="Youth Program"
          index="100"
          contents={[
            <div className="region_table">
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data100.regionModel}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>,
          ]}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dRegion: state.dEmployees.list,
  dPerson: state.dEmployees.list,
});

const mapActionToProps = {
  fetchAllRegion: actions.fetchAllRegion,
  fetchRegionPerson: actions.fetchRegionPerson,
};

export default connect(mapStateToProps, mapActionToProps)(EditableTable);
