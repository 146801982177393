import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.svg";
import { withRouter, NavLink, Link} from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import "../../config/global_styles.css";
import "./styles.css";

function Header() {
  //   useEffect(() => {
  //    // const user=localStorage.getItem('user');
  //     //if(user==='' || user==null){
  //       window.location.href = "/login";
  //       window.location.reload(false);

  //    // }
  // }, [])//componentDidMount

  //toast msg.
  const { addToast } = useToasts();

  const logout = () => {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
    window.location.href = "/";
    //  window.location.reload(false);
    setTimeout(() => {
      addToast("Logout successfully", { appearance: "success" });
    }, 1000);
  };
  return (
    <div className="header__wrapper">
      <div className="header">
              <div className="header__logo">
                  <Link to="/schedule">    
          <img src={logo} alt="twilio_logo" /></Link>
        </div>
        <nav className="navbar">
          <NavLink
            exact
            activeClassName="navbar__link--active"
            className="navbar__link"
            to="/schedule"
          >
            Schedule
          </NavLink>
          <NavLink activeClassName="navbar__link--active" className="navbar__link" to="/users">
            Users
          </NavLink>
          <NavLink activeClassName="navbar__link--active" className="navbar__link" to="/dashboard">
            Dashboard
          </NavLink>
          <NavLink
            activeClassName="navbar__link--active"
            className="navbar__link navbar__logout"
            onClick={() => logout()}
            to="/login"
          >
            LOG OUT
          </NavLink>
        </nav>
      </div>
    </div>
  );
}
export default withRouter(Header);
