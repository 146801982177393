import React from "react";
import { Collapse } from "antd";
import { DownOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import "antd/dist/antd.css";
import "./index.css";
import "./styles.css";

const { Panel } = Collapse;

function Accordian({ title, contents, index }) {
  return (
    <div className="accordian">
      <Collapse
        accordion
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 0 : 180} />
        )}
        className="site-collapse-custom-collapse"
      >
        <Panel header={title} key={index} className="panel_header">
          {contents.map((content, index) => (
            <div className="content" key={index}>
              {content}
            </div>
          ))}
        </Panel>
      </Collapse>
    </div>
  );
}
Accordian.propTypes = {
  title: PropTypes.string.isRequired,
  contents: PropTypes.array.isRequired,
};

Accordian.defaultProps = {
  title: "Region",
  contents: [],
};
export default Accordian;
