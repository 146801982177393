import React from "react";
import logo from "../../assets/images/logo.svg";
import "./styles.css";

function Footer() {
  return (
 <div></div>
  );
}

export default Footer;
