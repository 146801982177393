import React, { Component } from "react";
import { Switch, Select, Form, Button, TimePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ConfirmationModal from "../ConfirmationModal";
import moment from "moment";
import config from "../../config/config";
import { Prompt } from 'react-router'
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { authHeader } from "../../actions/auth-header";
import "./styles.css";
import Loader from '../Loader';

const timeformat = "HH:mm";
const Option = Select.Option;
const baseUrl = config.apiUrl + "Region/";
function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts();
        return <Component {...props} {...toastFuncs} />;
    };
}
const requestOptions = {
    headers: authHeader(),
};
class DayWiseShift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRegion: false,
            confirmationPopupVisible: false,
            userDopdownList: [],
            shiftList: [],
            startTimeForShift: "08:30",
            endTimeForShift: "18:30",
            isStartTime: true,
            isEndTime: true,
            confirmationPopupVisible: false,
            isStaffAway: false,
            transferRegionId: null,
            staffAwayTodayId: 0,
            regionList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            confirmationMessage: "",
            confirmationTitle: "",
            confirmationButton: "",
            deleteShiftId: 0,
            deletedShiftIndex: -1,
            isUnsaveToggle: false,
            transferedRegionIds: [],
            awayRegionIds: [],
            isLoading: false,
            IsSaveDisable: false,
            isDeletedMessage: false,
            shiftItem: { "shiftId": 0, "startTime": "", "endTime": "", "primaryTeamMember": null, "firstBackup": null, "secondBackup": null, "thirdBackup": null, "awayStaffThisShiftOnly": false, "regionId": this.props.regionId, "numberOfDay": this.props.day }
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.getUserList();
        this.getShiftList();
        this.GetAwayTransferedRegions();

    }




    getUserList() {
        axios
            .get(config.apiUrl + "User/GetUserDropdown/" + this.props.regionId, requestOptions)
            .then((response) => {

                this.state.userDopdownList = response.data;
                this.state.userDopdownList.map((e) => {
                    e.isAlreadySelected = false
                })
                this.setState({ userDopdownList: this.state.userDopdownList });
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    getTodatAwayDetails() {
        axios
            .get(config.apiUrl + "RegionDayStaffAway/GetRegionDayStaffAway?regionId=" + this.props.regionId + "&numberOfDay=" + this.props.day, requestOptions)
            .then((response) => {
                if (response.data) {
                    //  this.state.shiftList[0].awayStaffThisShiftOnly = response.data.todayAwayDay;
                    this.setState({
                        isStaffAway: response.data.todayAwayDay,
                        showRegion: response.data.todayAwayDay,
                        staffAwayTodayId: response.data.id,
                        transferRegionId: response.data.transferToRegionId,
                        shiftList: this.state.shiftList
                    });
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getShiftList() {
        axios
            .get(config.apiUrl + "ScheduleUserRegionDayShift/GetScheduleUserRegionDayShifts?regionId=" + this.props.regionId + "&numberOfDay=" + this.props.day, requestOptions)
            .then((response) => {
                var result = response.data;
                if (result.length > 0) {
                    this.setState({ shiftList: result });
                    this.getTodatAwayDetails();
                }
                else {
                    this.state.shiftList = [];
                    this.state.shiftList.push(this.state.shiftItem);
                    this.state.shiftList = JSON.parse(JSON.stringify(this.state.shiftList))
                    this.state.shiftList[0].startTime = "8:30:00.0000000";
                    this.state.shiftList[0].endTime = "18:30:00.0000000";
                    this.setState({ shiftList: this.state.shiftList });

                }

                this.setState({ isLoading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    GetAwayTransferedRegions() {
        axios
            .get(config.apiUrl + "RegionDayStaffAway/GetAwayTransferedRegions", requestOptions)
            .then((response) => {
                var result = response.data;
                result.transferedIds.map((value) => {
                    if (value.dayId == this.props.day)
                        this.state.transferedRegionIds.push(value.regionId)
                })
                result.todayAwayIds.map((value) => {
                    if (value.dayId == this.props.day)
                        this.state.awayRegionIds.push(value.regionId)
                })
                this.setState({ transferedRegionIds: this.state.transferedRegionIds, awayRegionIds: this.state.awayRegionIds });
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    toggle(checked) {
        this.state.shiftList.map((value, index) => { this.state.shiftList[index].awayStaffThisShiftOnly = checked; })

        // this.setAwayShiftToAll(checked);
        if (checked) {
            localStorage.setItem("isUnsaveToggle", true);
            this.setState({ showRegion: true, isStaffAway: true, shiftList: this.state.shiftList, isUnsaveToggle: true });
        } else {
            localStorage.setItem("isUnsaveToggle", true);
            this.setState({ showRegion: false, isStaffAway: false, shiftList: this.state.shiftList, isUnsaveToggle: true });
        }

    }

    setAwayShiftToAll = (isAway) => {
        axios
            .post(config.apiUrl + "ScheduleUserRegionDayShift/UpdateShiftToAwayToAll",
                {
                    "id": this.state.staffAwayTodayId,
                    "regionId": this.props.regionId,
                    "todayAwayDay": isAway,
                    "transferToRegionId": 0,
                    "numberOfDay": this.props.day

                },
                requestOptions)
            .then((response) => {

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    setStaffAwayToday = (transferedRegionId, isStaffAway) => {
        axios
            .post(config.apiUrl + "RegionDayStaffAway/UpdateRegionDayStaffAway",
                {
                    "id": this.state.staffAwayTodayId,
                    "regionId": this.props.regionId,
                    "todayAwayDay": isStaffAway,
                    "transferToRegionId": transferedRegionId,
                    "numberOfDay": this.props.day

                },
                requestOptions)
            .then((response) => {
                this.getTodatAwayDetails();
                this.GetAwayTransferedRegions();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleStartTime = (event, id) => {

        var tempArray = JSON.parse(JSON.stringify(this.state.shiftList))
        if (event != null) {
            tempArray[id].startTime = event.format("HH:mm");
            // tempArray[id].testStartTime = new Date(moment(event, timeformat)).getTime();
            this.setState({ isStartTime: true })
        }

        else {
            this.setState({ isStartTime: false })
            tempArray[id].startTime = "00:00"
        }
        this.setState({ shiftList: tempArray })
    }
    handleEndTime = (event, id) => {
        var tempArray = JSON.parse(JSON.stringify(this.state.shiftList));
        if (event != null) {
            tempArray[id].endTime = event.format("HH:mm");
            // tempArray[id].testEndTime = new Date(moment(event, timeformat)).getTime();;
            this.setState({ isEndTime: true })
        }

        else {
            this.setState({ isEndTime: false })
            tempArray[id].endTime = "00:00"
        }
        this.setState({ shiftList: tempArray })
    }
    handleTeamMember = (value, id) => {
        var tempArray = JSON.parse(JSON.stringify(this.state.shiftList));
        tempArray[id].primaryTeamMember = value;
        this.setState({ ...this.state, shiftList: tempArray })
    };
    handleFirstBackup = (value, id) => {
        var tempArray = JSON.parse(JSON.stringify(this.state.shiftList))
        tempArray[id].firstBackup = value;
        this.setState({ shiftList: tempArray })
    };
    handleSecondBackup = (value, id) => {
        var tempArray = JSON.parse(JSON.stringify(this.state.shiftList))
        tempArray[id].secondBackup = value;
        this.setState({ shiftList: tempArray })
    };
    handleThirdBackup = (value, id) => {
        var tempArray = JSON.parse(JSON.stringify(this.state.shiftList))
        tempArray[id].thirdBackup = value;
        this.setState({ shiftList: tempArray })
    };
    addShift = () => {
        // this.state.shiftItem.awayStaffThisShiftOnly = this.state.isStaffAway
        this.state.shiftList.push(this.state.shiftItem);
        this.setState({ shiftList: this.state.shiftList });
    }

    onFinish = (values) => {
        console.log("Received values of form:", values);
    };
    hidePopup = () => {
        this.setState({ confirmationPopupVisible: false });
    };

    handleRegion = (value) => {
        localStorage.setItem("isUnsaveToggle", true);
        this.setState({ transferRegionId: value, isUnsaveToggle: true })

    }

    setShiftAwayStatus = (value, id, shiftId) => {

        var notAwayShiftCount = 0;
        this.state.shiftList.map((item) => {
            if (item.awayStaffThisShiftOnly)
                notAwayShiftCount++;
        })

        if (!value && notAwayShiftCount < 2)
            return;
        var tempArray = JSON.parse(JSON.stringify(this.state.shiftList))
        tempArray[id].awayStaffThisShiftOnly = value;
        localStorage.setItem("isUnsaveToggle", true);
        this.setState({ shiftList: tempArray, isUnsaveToggle: true })

    }




    validate = () => {
        var isValid = true;
        var timeDiff = 0;
        this.state.shiftList.map((value, index) => {
            var shiftNumber = index + 1

            if (value.startTime == "") {
                this.props.addToast("Start Time is Requird for Shift " + shiftNumber, { appearance: "error" });
                isValid = false;
                return false;
            }
            if (value.startTime == "" && isValid) {
                this.props.addToast("End Time is Requird for Shift " + shiftNumber, { appearance: "error" });
                isValid = false;
                return false;
            }
            if ((value.primaryTeamMember == 0 || value.primaryTeamMember == null) && isValid) {
                this.props.addToast("Primary Team Member is Requird for Shift " + shiftNumber, { appearance: "error" });
                isValid = false;
                return false;
            }
            if (isValid && (value.firstBackup == 0 || value.firstBackup == null)) {
                this.props.addToast("First Backup is Requird for Shift " + shiftNumber, { appearance: "error" });
                isValid = false;
                return false;
            }

            if (new Date(moment(value.endTime, timeformat)).getTime() > new Date(moment("18:30", timeformat)).getTime()) {
                this.setState({
                    confirmationMessage: "Your shift end time cannot be later than 6:30 PM ",
                    confirmationButton: "Fix Shift Time",
                    confirmationTitle: "Error!",
                    confirmationPopupVisible: true,

                })
                isValid = false;
                return false;

            }
            if (new Date(moment(value.startTime, timeformat)).getTime() < new Date(moment("08:30", timeformat)).getTime()) {
                this.setState({
                    confirmationMessage: "Your shift start time cannot be earlier than 8:30 AM  ",
                    confirmationButton: "Fix Shift Time",
                    confirmationTitle: "Error!",
                    confirmationPopupVisible: true,

                })
                isValid = false;
                return false;

            }
            if (index > 0 && new Date(moment(value.startTime, timeformat)).getTime() < new Date(moment(this.state.shiftList[index - 1].endTime, timeformat)).getTime()) {
                this.setState({
                    confirmationMessage: "Your shift start time cannot be earlier then previous shift's end time ",
                    confirmationButton: "Fix Shift Time",
                    confirmationTitle: "Error!",
                    confirmationPopupVisible: true,

                })

                isValid = false;
                return false;
            }

            if (index > 0 && new Date(moment(value.startTime, timeformat)).getTime() > new Date(moment(this.state.shiftList[index - 1].endTime, timeformat)).getTime()) {
                this.setState({
                    confirmationMessage: "Your shift start time cannot be later then previous shift's end time  ",
                    confirmationButton: "Fix Shift Time",
                    confirmationTitle: "Error!",
                    confirmationPopupVisible: true,

                })
                isValid = false;
                return false;
            }


            timeDiff = timeDiff + ((new Date(moment(value.endTime, timeformat)).getTime() - new Date(moment(value.startTime, timeformat)).getTime()) / (60000));

        })
        if (!this.state.shiftList[this.state.shiftList.length - 1].endTime.includes("18:30") && isValid) {
            this.setState({
                confirmationMessage: "Your shift does not cover the whole day. Add another shift or update the end time",
                confirmationButton: "Fix Shift Time",
                confirmationTitle: "Error!",
                confirmationPopupVisible: true,

            })
            return false;
        }
        if (timeDiff < 600 && isValid) {
            this.setState({
                confirmationMessage: "Your shift does not cover the whole day. Add another shift or update the end time",
                confirmationButton: "Fix Shift Time",
                confirmationTitle: "Error!",
                confirmationPopupVisible: true,

            })
            return false;
        }
        return isValid;
    }
    filterUserForPrimaryMember = (id, index) => {
        if (this.state.shiftList[index].firstBackup != id
            && this.state.shiftList[index].secondBackup != id
            && this.state.shiftList[index].thirdBackup != id)
            return true;
        else
            return false;
    }
    filterUserForFirstBackup = (id, index) => {
        if (this.state.shiftList[index].primaryTeamMember != id
            && this.state.shiftList[index].secondBackup != id
            && this.state.shiftList[index].thirdBackup != id)
            return true;
        else
            return false;
    }
    filterUserForSecondBackup = (id, index) => {
        if (this.state.shiftList[index].firstBackup != id
            && this.state.shiftList[index].primaryTeamMember != id
            && this.state.shiftList[index].thirdBackup != id)
            return true;
        else
            return false;
    }
    filterUserForThirdBackup = (id, index) => {
        if (this.state.shiftList[index].firstBackup != id
            && this.state.shiftList[index].secondBackup != id
            && this.state.shiftList[index].primaryTeamMember != id)
            return true;
        else
            return false;
    }
    hidePopup = () => {
        this.setState({ confirmationPopupVisible: false });
    }
    onCancel = () => {
        this.getShiftList();
    }

    onDeleteShift = (id, index, isStaffAway) => {
        if (this.state.isStaffAway && isStaffAway)
            return;
        id > 0 ? this.setState({
            confirmationMessage: "Are you sure you want to delete this shift? If yes, click delete below then adjust the time of any remaining shift to cover the gaps in the day, then click save "
        }) :
            this.setState({ confirmationMessage: "Are you sure you want to delete this shift" });
        this.setState({
            confirmationButton: "DELETE",
            confirmationTitle: "Please Confirm",
            confirmationPopupVisible: true,
            deleteShiftId: id,
            deletedShiftIndex: index
        })




    }

    onSubmit = () => {

        if (this.state.isStaffAway && this.state.transferRegionId == null) {
            this.setState({
                confirmationMessage: "Your must select a region to send calls to when staff is away",
                confirmationButton: "SELECT REGION",
                confirmationTitle: "Error!",
                confirmationPopupVisible: true,

            })
            return;
        }
        const newUrl = "ScheduleUserRegionDayShift/UpdateShift";
        if (this.validate()) {
            this.setState({ IsSaveDisable: true });
            axios.post(
                config.apiUrl + newUrl,
                {
                    "shiftList": this.state.shiftList,
                    "deleteShiftId": this.state.deleteShiftId
                },
                requestOptions
            ).then((res) => {
                if (res) {
                    this.state.deleteShiftId > 0 ?
                        this.setState({ isDeletedMessage: true, deleteShiftId: 0 }) :
                        this.props.addToast("Shift Saved successfully", { appearance: "success" });

                    this.setState({ shiftList: res.data, IsSaveDisable: false });
                }



            });
            if (this.state.isUnsaveToggle) {
                if (this.state.transferRegionId) {
                    this.setStaffAwayToday(this.state.transferRegionId, this.state.isStaffAway);
                    localStorage.setItem("isUnsaveToggle", false);
                    this.setState({ isUnsaveToggle: false });
                }
            }



        }
    }

    performAction = () => {
        if (this.state.confirmationTitle == "Error!") {
            this.setState({ confirmationPopupVisible: false });
        }
        if (this.state.confirmationButton == "DELETE") {
            this.state.shiftList.splice(this.state.deletedShiftIndex, 1);
            this.setState({ shiftList: this.state.shiftList });
            this.setState({ confirmationPopupVisible: false });
        }
    }

    getDisabledminute(e) {
        if (e == -1) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
                30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]
        }
        if (e == 8)
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 0];

    }

    getEndDisabledminute = (e) => {
        if (e == -1) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
                30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]
        }
        if (e == 18)
            return [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

    }

    render() {
        const { showRegion, confirmationPopupVisible } = this.state;
        var totalShift = 0;

        return (
            <div>
                {
                    this.state.isLoading ? <Loader /> :
                        <div className="dayWise__container">
                            {this.state.isDeletedMessage && (
                                <span className="deleteShift__popup" >
                                    Shift Successfully Deleted
                                    <span onClick={() => this.setState({ isDeletedMessage: false })}>CLOSE</span>
                                </span>)}


                            <div className="regionStaff">
                                <div className="region__switch">
                                    <p>Staff Is Away Today</p>
                                    <Switch size="small" disabled={this.state.transferedRegionIds.includes(this.props.regionId)} checked={this.state.isStaffAway} onChange={this.toggle} className="switchClass" />
                                </div>
                                {showRegion && (
                                    <div className="toggleRegions">
                                        <p>Send Calls to Region</p>
                                        <Select
                                            placeholder="Region"
                                            name="region"
                                            value={this.state.transferRegionId}
                                            onChange={this.handleRegion}
                                        // value={userModal.region}
                                        >
                                            {this.state.regionList.map((item) => {
                                                if (this.props.regionId != item && !this.state.awayRegionIds.includes(item))
                                                    return (<Option key={item} value={item}>{item}</Option>)
                                            })}
                                        </Select>
                                    </div>
                                )}
                            </div>
                            {/* shifts */}
                            <div className="shifts__container">
                                <Form
                                    name="dynamic_form_nest_item"
                                    onFinish={this.onFinish}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <>
                                        {this.state.shiftList.map((value, index) => {
                                            { totalShift = totalShift + 1 }
                                            return (

                                                <div key={index + 1} className="schedule__shifts">

                                                    <div className="shifts__row">
                                                        <p>SHIFT {index + 1}</p>
                                                        {index != 0 &&

                                                            <Button
                                                                disabled={this.state.isStaffAway && value.awayStaffThisShiftOnly}
                                                                onClick={() => this.onDeleteShift(value.shiftId, index, value.awayStaffThisShiftOnly)}
                                                                className="shiftRow__dlt"
                                                            >
                                                                <span>DELETE THIS SHIFT</span>
                                                                <i className="fas fa-trash" disabled={this.state.isStaffAway && value.awayStaffThisShiftOnly} />
                                                            </Button>
                                                        }

                                                    </div>
                                                    <div className="shifts__formSwitch">
                                                        <div className="shifts__form">
                                                            <Form.Item

                                                                //   name={[index, "startTime"]}
                                                                //  fieldKey={[index, "startTime"]}
                                                                //   rules={[{ required: true, message: "Select start time" }]}
                                                                label="Start Time"
                                                                className="shift__field"
                                                            >
                                                                <TimePicker
                                                                    use12Hours
                                                                    minuteStep={15}
                                                                    disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23]}
                                                                    disabledMinutes={(e) => this.getDisabledminute(e)}
                                                                    disabled={this.state.isStaffAway && value.awayStaffThisShiftOnly}
                                                                    onChange={(e) => this.handleStartTime(e, index)}
                                                                    value={value.startTime != "" ? moment(value.startTime, timeformat) : ""}
                                                                    format="hh:mm A"
                                                                    className="dashboard__datepicker"
                                                                />
                                                            </Form.Item>
                                                            <Form.Item

                                                                label="End Time"
                                                                className="shift__field"
                                                            >
                                                                <TimePicker
                                                                    minuteStep={15}
                                                                    disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23]}
                                                                    disabledMinutes={(e) => this.getEndDisabledminute(e)}
                                                                    disabled={this.state.isStaffAway && value.awayStaffThisShiftOnly}
                                                                    onChange={(e) => this.handleEndTime(e, index)}
                                                                    value={value.endTime != "" ? moment(value.endTime, timeformat) : ""}
                                                                    format="hh:mm A"
                                                                    className="dashboard__datepicker"
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {this.state.isStaffAway && (

                                                            <div className="region__switch">
                                                                <p style={{ paddingRight: 15 }}>Staff is away this shift</p>
                                                                <Switch
                                                                    size="small"
                                                                    checked={value.awayStaffThisShiftOnly}
                                                                    onChange={(e) => this.setShiftAwayStatus(e, index, value.shiftId)}
                                                                    className="switchClass"
                                                                />
                                                            </div>
                                                        )

                                                        }

                                                    </div>
                                                    <div className="shifts__primarySelect">
                                                        <Form.Item
                                                            // name={[index, "primaryMember"]}
                                                            //  fieldKey={[index, "primaryMember"]}
                                                            //  rules={[{ required: true, message: "Select Primary Member" }]}
                                                            label="Primary Team Member"
                                                            // value={value.primaryTeamMember}
                                                            className="shift__field"
                                                        >
                                                            <Select
                                                                showSearch
                                                                disabled={this.state.isStaffAway && value.awayStaffThisShiftOnly}
                                                                placeholder="Primary Team Member"
                                                                name={[index, "primaryMember"]}
                                                                onChange={(e) => this.handleTeamMember(e, index)}
                                                                value={value.primaryTeamMember}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            >
                                                                {this.state.userDopdownList && this.state.userDopdownList.length > 0 && this.state.userDopdownList.map((e) => {
                                                                    // if (e.isPrimaryRegion)
                                                                    return (
                                                                        <Option value={e.key} key={e.key}>{e.name}</Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="shifts__selectRow">
                                                        <Form.Item

                                                            // name={[index, "firstBackup"]}
                                                            // fieldKey={[index, "firstBackup"]}
                                                            // rules={[{ required: true, message: "Select First Backup" }]}
                                                            label="First Backup"
                                                            className="shift__field"
                                                        >
                                                            <Select
                                                                showSearch
                                                                disabled={this.state.isStaffAway && value.awayStaffThisShiftOnly}
                                                                placeholder="First Backup"
                                                                name="firstBackup"
                                                                onChange={(e) => this.handleFirstBackup(e, index)}
                                                                value={value.firstBackup}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            >
                                                                {this.state.userDopdownList && this.state.userDopdownList.length > 0 && this.state.userDopdownList.map((e) => {
                                                                    //    if (this.filterUserForFirstBackup(e.key, index))
                                                                    return <Option value={e.key} key={e.key}>{e.name}</Option>;

                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item

                                                            //  name={[index, "secondBackup"]}
                                                            // fieldKey={[index, "secondBackup"]}
                                                            // rules={[{ required: true, message: "Select Second Backup" }]}
                                                            label="Second Backup"
                                                            className="shift__field"
                                                        >
                                                            <Select
                                                                showSearch
                                                                disabled={this.state.isStaffAway && value.awayStaffThisShiftOnly}
                                                                placeholder="Second Backup"
                                                                name="secondBackup"
                                                                onChange={(e) => this.handleSecondBackup(e, index)}
                                                                value={value.secondBackup}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            >
                                                                <Option value={0} >No Backup</Option>
                                                                {this.state.userDopdownList && this.state.userDopdownList.length > 0 && this.state.userDopdownList.map((e) => {
                                                                    //  if (this.filterUserForSecondBackup(e.key, index))
                                                                    return (
                                                                        <Option value={e.key} key={e.key}>{e.name}</Option>
                                                                    );

                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item

                                                            //name={[index, "thirdBackup"]}
                                                            // fieldKey={[index, "thirdBackup"]}
                                                            // rules={[{ required: true, message: "Select Third Backup" }]}
                                                            label="Third Backup"
                                                            className="shift__field"
                                                        >
                                                            <Select
                                                                showSearch
                                                                disabled={this.state.isStaffAway && value.awayStaffThisShiftOnly}
                                                                placeholder="Third Backup"
                                                                name="thirdBackup"
                                                                onChange={(e) => this.handleThirdBackup(e, index)}
                                                                value={value.thirdBackup}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            >
                                                                <Option value={0} >No Backup</Option>
                                                                {this.state.userDopdownList && this.state.userDopdownList.length > 0 && this.state.userDopdownList.map((e) => {
                                                                    //   if (this.filterUserForThirdBackup(e.key, index))
                                                                    return (
                                                                        <Option value={e.key} key={e.key}>{e.name}</Option>
                                                                    );

                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {totalShift < 8 && (
                                            <Form.Item>

                                                <Button
                                                    //disabled={this.state.isStaffAway}
                                                    type="text"
                                                    className="add__shift"
                                                    onClick={() => this.addShift()}
                                                    block
                                                    icon={<PlusOutlined className="add__icon" />}
                                                >
                                                    Add Another Shift
                                                </Button>

                                            </Form.Item>
                                        )}
                                    </>


                                </Form>
                                <div className="shifts__btn">
                                    <Button type="default" size="large" onClick={() => this.onCancel()} className="shift__cancel">
                                        CANCEL
                                    </Button>
                                    <Button type="primary" size="large" onClick={() => this.onSubmit()} className="shift__save" disabled={this.state.IsSaveDisable}>
                                        SAVE
                                    </Button>
                                </div>
                            </div>
                            <ConfirmationModal
                                title={this.state.confirmationTitle}
                                error={this.state.confirmationTitle == "Error!" ? true : false}
                                errorStyle={this.state.confirmationTitle == "Error!" ? true : false}
                                visible={confirmationPopupVisible}
                                buttonName={this.state.confirmationButton}
                                message={this.state.confirmationMessage}
                                hidePopup={this.hidePopup}
                                PerformAction={this.performAction}
                            />
                            {this.state.isUnsaveToggle &&
                                <Prompt

                                    when={this.state.isUnsaveToggle}
                                    message='You have unsaved changes, are you sure you want to leave?'
                                />
                            }

                        </div>
                }

            </div>
        );
    }
}
export default withToast(DayWiseShift);
