import React, { Component } from "react";
import { Modal, Button, Form, Select, Input, InputNumber, Checkbox } from "antd";
import { useToasts } from "react-toast-notifications";
import config from "../../config/config";
import { authHeader } from "../../actions/auth-header";
import InputMask from "react-input-mask";
import "./styles.css";
import axios from "axios";
import lodash from "lodash";

const Option = Select.Option;
const baseUrl = config.apiUrl + "User/";
const requestOptions = {
    headers: authHeader(),
};

function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts();
        return <Component {...props} {...toastFuncs} />;
    };
}

class AddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideModal: false,
            loading: false,
            name: "",
            phoneNumber: "",
            email: "",
            region: "",
            role: "",
            receiveVoiceEmails: true,
            isAnAdmin: false,
            LastInsertId: 0,
        };
    }

    componentDidUpdate(prevProps) {
        const prevPropsValues = {
            visible: prevProps.visible,
        };
        const currentPropsValues = {
            visible: this.props.visible,
        };

        if (!lodash.isEqual(prevPropsValues, currentPropsValues)) {
            this.resetForm();
        }
    }

    handleName = (e) => {
        e.preventDefault();
        this.setState({ name: e.target.value });
    };
    handleNumber = (e) => {
        this.setState({ phoneNumber: e.target.value.replace(/[^0-9]+/g, "")});
    };
    handleEmail = (e) => {
        e.preventDefault();
        this.setState({ email: e.target.value });
    };
    handleRegion = (value) => {
        this.setState({ region: value });
    };
    handleRole = (value) => {
        this.setState({ role: value });
    };
    handleReceiveVoiceEmails = (e) => {
        this.setState({ receiveVoiceEmails: e.target.checked });
    };
    handleIsAnAdmin = (e) => {
        this.setState({ isAnAdmin: e.target.checked });
    };

    resetForm() {
        this.setState({
            name: "",
            phoneNumber: "",
            email: "",
            region: null,
            role: null,
            receiveVoiceEmails: true,
            isAnAdmin: false
        })
    }
    validate = () => {
        var isDublicateEmail = false;
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (this.state.name === null || this.state.name === "") {
            this.props.addToast("User Name Required!", { appearance: "error" });
            return false;
        }
        if (this.state.phoneNumber === null || this.state.phoneNumber === "") {
            this.props.addToast("User Number Required!", { appearance: "error" });
            return false;
        }
        if (this.state.email === null || this.state.email === "") {
            this.props.addToast("Email Address Required!", { appearance: "error" });
            return false;
        }
        else if (!pattern.test(this.state.email)) {
            this.props.addToast("Please Enter correct Email!", { appearance: "error" });
            return false;
        }
        if (this.state.region === null || this.state.region === "") {
            this.props.addToast("User Region Required!", { appearance: "error" });
            return false;
        }
        if (this.state.role === null || this.state.role === "") {
            this.props.addToast("User Role Required!", { appearance: "error" });
            return false;
        }
        if (this.state.phoneNumber != null) {
            //  const numOfDig = this.state.phoneNumber.toString();
            if (this.state.phoneNumber.toString().length >= 11 || this.state.phoneNumber.toString().length <= 9) {
                this.props.addToast("User Number must be 10 digit!", {
                    appearance: "error",
                });
                return false;
            }
        }
        this.props.userList && this.props.userList.length>0 && this.props.userList.map((e) => {
            if (e.email == this.state.email) {
                isDublicateEmail = true;
            }
        })
        if (isDublicateEmail) {
            this.props.addToast("User Email Already Exist!", { appearance: "error" });
            return false;
        }

        return true;
    };

    handleOk = (e) => {
        if (this.validate()) {
            const newUrl = "AddEmployee";
            axios
                .post(
                    baseUrl + newUrl,
                    {
                        key: 0,
                        name: this.state.name,
                        phoneNumber: this.state.phoneNumber.toString(),
                        email: this.state.email,
                        region: this.state.region,
                        role: this.state.role,
                        receiveVoiceEmails: this.state.receiveVoiceEmails,
                        isAnAdmin: this.state.isAnAdmin,
                    },
                    requestOptions
                )
                .then((res) => {
                    this.setState({ loading: false, hideModal: false });
                    // this.setState({ loading: true });
                    this.props.hideForm(true);

                    this.props.LastInsertId(res.data);
                });

            //this.props.addToast("Submitted successfully", { appearance: "success" });
            // setTimeout(() => {
            //   this.setState({ loading: false, hideModal: false });
            //   this.props.hideForm(true);
            //   window.location.reload(false);
            // }, 1000);
        }
    };

    render() {
        const { visible, hideForm, userList } = this.props;
        const { loading } = this.state;
        return (
            <div>
                <Modal
                    title="ADD A USER"
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={hideForm}
                    footer={[
                        <Button type="reset" key="back" onClick={hideForm} size="large" className="common_btn cancel_btn">
                            CANCEL
            </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            size="large"
                            loading={loading}
                            onClick={this.handleOk}
                            className="common_btn save_btn"
                        >
                            SAVE NEW User
            </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "User Name Required!",
                                },
                            ]}
                        >
                            <Input
                                className="modal__input"
                                placeholder="Full Name"
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleName}
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "User Phone Number Required!",
                                },
                            ]}
                        >
                            <InputMask
                                className="modal__number_input"
                                placeholder="Phone Number"
                                type="text"
                                name=" phoneNumber"                               
                                value={this.state.phoneNumber}
                                onChange={this.handleNumber}
                                mask="999-999-9999"
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "User Name Required!",
                                },
                            ]}
                        >
                            <Input
                                className="modal__input"
                                placeholder="Email Address"
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleEmail}
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Email Address Required!",
                                },
                            ]}
                        >
                            <Select placeholder="Primary Region" name="region" value={this.state.region} onChange={this.handleRegion}>
                                {/* <Option value={""}>Region</Option> */}
                                <Option value="Region 1">Region 1</Option>
                                <Option value="Region 2">Region 2</Option>
                                <Option value="Region 3">Region 3</Option>
                                <Option value="Region 4">Region 4</Option>
                                <Option value="Region 5">Region 5</Option>
                                <Option value="Region 6">Region 6</Option>
                                <Option value="Region 7">Region 7</Option>
                                <Option value="Region 8">Region 8</Option>
                                <Option value="Region 9">Region 9</Option>
                                <Option value="Region 10">Region 10</Option>
                                <Option value="Youth Program">Youth Program</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "User Role Required!",
                                },
                            ]}
                        >
                            <Select placeholder="Role" name="role" onChange={this.handleRole} value={this.state.role}>
                                {/* <Option value={""}>Role> */}
                                <Option value="Navigator">Navigator</Option>
                                <Option value="Backup1">Coach</Option>
                                <Option value="Backup2">Coordinator</Option>
                                <Option value="Backup3">Regional Director</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Checkbox
                                name="receiveVoiceEmails"
                                checked={this.state.receiveVoiceEmails}
                                onChange={this.handleReceiveVoiceEmails}
                                className="user__checkbox"
                            >
                                User should receive voicemails
              </Checkbox>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Checkbox
                                name="isAnAdmin"
                                checked={this.state.isAnAdmin}
                                onChange={this.handleIsAnAdmin}
                                className="user__checkbox"
                            >
                                User is an admin
              </Checkbox>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
export default withToast(AddModal);
