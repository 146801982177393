import React from "react";
import "./styles.css";

function Tabs({ selected, region, onClick, tabstyles }) {
  return (
    <div className={`tabContainer ${tabstyles} ${selected ? "activeTab" : ""}`} onClick={onClick}>
      <p>{region}</p>
    </div>
  );
}
export default Tabs;
